import {
  MintPlasma,
  ApprovePlasma,
  GameAuth,
  GetAllStarData,
  GetAllowance,
  NetworkAuth,
  SubscribeOnAccountChanging,
} from "./blockchain";
import { GetStarsCount } from "./blockchain/functions/starnft";
import { WebSocket } from "ws";
import { CreateNewStar } from "./blockchain";
import { InitGame } from './game'

const adrin: HTMLInputElement = document.querySelector("input");

const AuthTest = () => {
  NetworkAuth().then((res) => {
    alert(res);
  });
};

const GameAuthTest = async () => {
  const address = adrin ? adrin.value : "";
  let startRequested = false;
  let strt = false;
  if (!strt) {
    const ws: WebSocket | null = await GameAuth(address);
    console.log("Connection : ");
    console.log(ws);

    if (ws) {
      ws.onmessage = (event) => {
        console.log(event);
        let dt = event.data
        console.log(dt);
        if (dt) {
           try {
              let data = JSON.parse(String(dt))
              console.log(data)
              if (data.action === "objectlist") {
                 InitGame (ws, data.list)
                 ws.onmessage = null
              }
           } catch (e) {
             console.log(e.message)
           }
        }
      };
      console.log(ws);
      ws.send(
        JSON.stringify({
          action: "entergame",
        })
      );
    }
    strt = true;
  }
};

const SubscribeTest = () => {
  SubscribeOnAccountChanging().then((res) => {
    alert(res);
  });
};

const GetAllTest = () => {
  GetAllStarData().then((res) => {
    console.log("dt : ");
    console.log(res);
    alert(res);
  });
};

const authCtnr = document.getElementById("auth");
const subCtnr = document.getElementById("subscribeauth");
const alloCtnr = document.getElementById("plasmaallowance");
const apprCtnr = document.getElementById("plasmaapprove");
const starCtnr = document.getElementById("getall");

if (authCtnr) {
  authCtnr.addEventListener("click", AuthTest);
}

if (subCtnr) {
  subCtnr.addEventListener("click", GameAuthTest);
}
/*
if (alloCtnr) { 
    alloCtnr.addEventListener('click', AllowanceTest )
}
*/

if (starCtnr) {
  starCtnr.addEventListener("click", GetAllTest);
}
