import React from "react";
import ReactDOM from "react-dom/client";
import Phaser from "phaser";

export function InitGame(ws, objList = []) {
  const frame = {
    width: 800,
    height: 1400,
  };

  let config = {
    type: Phaser.AUTO,
    width: frame.width,
    height: frame.height,
    backgroundColor: "black",
    parent: "gamefield",
    physics: {
      default: "arcade",
      arcade: {
        gravity: { x: 0, y: 0 },
      },
    },
    scene: {
      preload: preload,
      create: create,
      update: update,
    },
    fps: {
      target: 1, // Set your desired frame rate here
    },
  };

  let game = new Phaser.Game(config);
  let objectList = objList;
  let mirror = false;
  let owner1 = "0x202";
  let owner2 = "0x494";
  let planet1;
  let planet2;
  let angle = 0;
  let distance = 150;
  let planetr1;
  let planetr2;
  let btn1;
  let btn2;
  let btn3;
  let btn4;
  let btn5;
  let shipsByOwner2 = [];
  let shipsByOwner1 = [];
  let shipCreationTiming = 5000;
  let idList = [];
  const battleLine = 500;
  const planetPos2 = 800;

  function preload() {
    this.load.image("sky", "/sprites/sky.jpg");
    this.load.image("star", "/sprites/star.png");
    this.load.image("ship", "/sprites/ship.png");
    this.load.image("ray", "/sprites/ray.png");
    this.load.image("planet", "/sprites/planet.png");
    this.load.image("planetr", "/sprites/planetr.png");
    this.load.image("control", "/sprites/control.png");
    this.load.image("bullet", "/sprites/bullet.png");
    this.load.spritesheet("shell", "/sprites/bullet.png", {
      frameWidth: 16,
      frameHeight: 16,
    });
  }

  let frames = 0;

  function create() {
    owner1 = document.querySelector("input").value.toLowerCase();
    const text = this.add.text(50, 1000, owner1, {
      fontSize: "24px",
      fill: "#00ff00",
      fontFamily: "Arial",
    });

    this.frameCounterText = this.add.text(100, 10, "Frame: 0", {
      fontFamily: "Arial",
      fontSize: 24,
      color: "#ffffff",
    });

    objectList.forEach((obj) => {
      const owner = obj.owner.toLowerCase();
      if (owner !== owner1) {
        owner2 = owner;
      } else if (obj.mirror == true) {
        mirror = true;
      }
      switch (obj.class) {
        case "star":
          let yCoord = mirror ? battleLine * 2 - obj.center.y : obj.center.y;
          if (owner === owner1) {
            idList.push({
              id: obj.id,
              sprite: this.add.sprite(obj.center.x, yCoord, "star"),
            });
          } else {
            idList.push({
              id: obj.id,
              sprite: this.add.sprite(obj.center.x, yCoord, "star"),
            });
          }
          break;
        case "planet":
          let yCoordp = mirror ? battleLine * 2 - obj.center.y : obj.center.y;
          if (owner === owner1) {
            planet1 = this.add.sprite(obj.center.x, yCoordp, "planet");
            idList.push({
              id: obj.id,
              sprite: planet1,
            });
            planetr1 = this.add.sprite(obj.center.x, yCoordp, "planetr");
            idList.push({
              id: obj.id + "o",
              sprite: planetr1,
            });
          } else {
            planet2 = this.add.sprite(obj.center.x, yCoordp, "planet");
            idList.push({
              id: obj.id + "o",
              sprite: planet2,
            });
            planetr2 = this.add.sprite(obj.center.x, yCoordp, "planetr");
            idList.push({
              id: obj.id,
              sprite: planetr2,
            });
          }
      }
    });

    console.log("ids : ");
    console.log(idList);

    this.add.text(50, 50, owner2, {
      fontSize: "24px",
      fill: "#00ff00",
      fontFamily: "Arial",
    });

    ws.onmessage = (event) => {
      console.log("Processed in game : ");

      let dt;

      try {
        let sdt = JSON.parse(event.data);

          console.log(sdt);
          console.log(sdt.action);

      } catch (e) {
        return;
      }
      try {
        dt = JSON.parse(JSON.parse(event.data));
      } catch (e) {
        dt = JSON.parse(event.data);
      }

      if (dt.action == "objectcreate") {
        console.log("Creating ...");
        console.log(dt);
        let list = dt.list;
        try {
          let owner1num = 0;
          let owner2num = 0;
          list.forEach((item) => {
            let sprite;
            switch (item.class) {
              case "ship":
                let yCoord =
                  item.owner === owner1 ? battleLine + 50 : battleLine - 50;
                let xCoord =
                  (config.width / 2) -
                  60 +
                  (item.owner === owner1 ? owner1num * 60 : owner2num * 60);
                sprite = this.add.sprite(xCoord, yCoord, "ship");
                if (item.owner === owner1) {
                  owner1num++
                } else {
                  owner2num++
                }
            }
            idList.push({
              id: item.id,
              sprite: sprite,
            });
          });
        } catch (e) {
          console.error(e.message);
        }
      }

      if (dt.action === "objectupdate") {
        console.log("Updating ...");
        console.log(dt);
      }

      if (dt.action === "objectdestroy") {
        console.log("Destroying ...");
        if (dt.data) {
          let id = dt.data.id
          idList.forEach((item) => {
            if (item.id === id) {
              item.sprite.destroy()
            }
          })
        }
        console.log(dt);
      }

      if (dt.planet) {
        if (planetr1 && planetr2) {
          if (mirror) {
            if (dt.owner === owner1) {
              planetr1.x = config.width / 2 + distance * Math.cos(dt.angle);
              planetr1.y = 200 + distance * Math.sin(dt.angle);
              planetr1.setRotation(dt.rotation);
            }

            if (dt.owner === owner2) {
              planetr2.x = config.width / 2 - distance * Math.cos(dt.angle);
              planetr2.y = planetPos2 - distance * Math.sin(dt.angle);
              planetr2.setRotation(dt.rotation);
            }
          } else {
            if (dt.owner === owner1) {
              planetr2.x = config.width / 2 + distance * Math.cos(dt.angle);
              planetr2.y = 200 + distance * Math.sin(dt.angle);
              planetr2.setRotation(dt.rotation);
            }

            if (dt.owner === owner2) {
              planetr1.x = config.width / 2 - distance * Math.cos(dt.angle);
              planetr1.y = planetPos2 - distance * Math.sin(dt.angle);
              planetr1.setRotation(dt.rotation);
            }
          }
        }
      }
    };

    btn1 = this.add.sprite(config.width / 2 - 50, 1100, "control");
    btn2 = this.add.sprite(config.width / 2 + 50, 1100, "control");
    btn3 = this.add.sprite(config.width / 2 - 100, 1190, "control");
    btn4 = this.add.sprite(config.width / 2 + 0, 1190, "control");
    btn5 = this.add.sprite(config.width / 2 + 100, 1190, "control");
    console.log(planet1);

    let shipCreationTimer = this.time.addEvent({
      delay: 60000, // Интервал в миллисекундах (1 минута = 60 000 миллисекунд)
      callback: createNewShips,
      callbackScope: this,
      loop: true, // Повторять таймер после каждого вызова
    });

    function createNewShips() {
      console.log("ok");
    }
  }

  function update() {
    // Update planet position based on angle and distance
    frames++;
    this.frameCounterText.setText("Frames " + frames);
    if (planet1 && planet2) {
      planet1.x = config.width / 2 + distance * Math.cos(angle);
      planet1.y = 200 + distance * Math.sin(angle);

      planet2.x = config.width / 2 - distance * Math.cos(angle);
      planet2.y = planetPos2 - distance * Math.sin(angle);

      planet1.setRotation(planet1.rotation + 0.01);
      planet2.setRotation(planet2.rotation + 0.01);

      // Rotate the planet around the star
      angle += 0.000694;

      // Keep the angle within the range [0, 2 * PI]
      if (angle >= Math.PI * 2) {
        angle -= Math.PI * 2;
      }
    }
  }

  // game.setUpdateInterval(1);
  /* setInterval(() => {
    console.log("Sync : ")
    console.log(angle)
    console.log(planet1.rotation)
  }, 1000) */
}
