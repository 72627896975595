import { w3cwebsocket as WebSocket } from "websocket";
import Web3 from "web3";
import { wsServerUrl, env } from "../config";

export async function GameAuth(account: string): Promise<WebSocket | null> {
  if (!env || !account) {
    return null;
  }

  const wss = new WebSocket(wsServerUrl);
  const web3 = new Web3(env);

  return await new Promise((resolve) => {
    wss.onopen = (ws: WebSocket) => {
      let isAuthRequested = false;
	  const PingPong = setInterval(() => {
                wss.send('ping');
              },3000);
      wss.onmessage = async (message: any) => {

	    if (String(message.data) === 'pong') {
           return;
        /* setTimeout(() => {
          wss.send('ping');
        }, 3000); */
		}

        try {
          const msg = JSON.parse(message.data);
		  		console.log('message : ')
	    console.log(message)
          console.log(ws)

          if (msg.action === "auth" && msg.state === "success") {
            // wss.onmessage = null;
            resolve(wss);
            return wss;
          }

          if (!isAuthRequested) {
            const dt = new Date().getTime();
            const signMsg = "auth_" + String(dt - (dt % 600000));
            const signature = await web3.eth.personal.sign(
              signMsg,
              account,
              ""
            );
            wss.send(
              JSON.stringify({
                action: "auth",
                signature: signature,
              })
            );
            isAuthRequested = true;
          }
        } catch (e) {
          console.log(e.message);
        }
      };

      wss.onclose = () => {
        console.log("closed");
		clearInterval(PingPong)
        resolve(null);
        return null;
      };
    };
  });
}

export async function EnterGame(ws: WebSocket) {
  return await new Promise((resolve) => {
    ws.send(
      JSON.stringify({
        action: "entergame",
      })
    ).then(() => {
      resolve(true);
    });
  });
}
